import React from 'react'
import styled from '@emotion/styled'
import ImageGallery from 'react-image-gallery'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Box, Row, Column, imageGalleryCss } from '../components/ui'

import img1 from '../images/st-1.jpg'
import img2 from '../images/st-2.jpg'
import img3 from '../images/st-3.jpg'
import img4 from '../images/st-4.jpg'
import img5 from '../images/st-5.jpg'

const Ul = styled('ul')`
  font-size: 14px;
`

const Table = styled('table')`
  margin-top: 24px;
  font-size: 13px;

  td,
  th {
    padding: 2px 0;
  }

  thead tr {
    border-bottom: 2px solid #111;
  }
`

const Page = () => (
  <Layout routePath="prairie-wood-products">
    <SEO title="Prairie Wood Products" />

    <Box mt={5} fontSize={30} fontWeight={300}>
      Prairie Wood Products of MN
    </Box>

    <Row width={1} mt={3}>
      <Column flex={1.15}>
        <Box my={4}>
          <Box fontSize={28} fontWeight={300}>
            Pallet Parts
          </Box>
          <Box>
            <Box pt={3} fontSize={15}>
              We do custom resawing to manufacture custom pallet parts and rough-cut lumber from
              various species.
            </Box>
            <Ul>
              <li>Drying capabilities</li>
              <li>All wood can be wet or dry</li>
            </Ul>
          </Box>
        </Box>

        <Box mb={4}>
          <Box fontSize={28} fontWeight={300}>
            Stringers
          </Box>

          <Box>
            <Ul>
              <li>Size: 1 ¼" x 3 ½" x 48"</li>
              <li>Custom sizes available upon request</li>
            </Ul>
          </Box>
        </Box>

        <Box>
          <Box fontSize={28} fontWeight={300}>
            Stakes
          </Box>
          <Ul>
            <li>Stakes available in chisel point and pencil point.</li>
            <li>All bundles are double banded for easy handling.</li>
            <li>
              Made using kiln-dried wood. This ensures no cracking or warping, producing harder and
              more reusable stakes.
            </li>
          </Ul>
        </Box>
      </Column>

      <Box width={64}></Box>

      <Box flex={1} css={imageGalleryCss({ height: 330 })}>
        <ImageGallery
          showPlayButton={false}
          showFullscreenButton={false}
          items={[
            { original: img1, thumbnail: img1 },
            { original: img2, thumbnail: img2 },
            { original: img3, thumbnail: img3 },
            { original: img4, thumbnail: img4 },
            { original: img5, thumbnail: img5 },
          ]}
        />
      </Box>
    </Row>

    <Box>
      <Table>
        <thead>
          <tr>
            <th>Dimensions</th>
            <th>Point Style</th>
            <th>Bundle Size</th>
            <th>Bundles Per Pallet</th>
            <th>Actual Dimensions</th>
          </tr>
        </thead>

        <tbody>
          {stakes.map((row, index) => (
            <tr key={index}>
              <td>{row.dimensions}</td>
              <td>{row.style}</td>
              <td>{row.bundle_size}</td>
              <td>{row.bundles_per_pallet}</td>
              <td>{row.actual}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      <em css={{ fontSize: 15 }}>CUSTOM SIZES AVAILABLE UPON REQUEST.</em>
    </Box>

    <Box mt={5} fontSize={28} fontWeight={300}>
      Lath
    </Box>

    <Box>
      <Ul>
        <li>
          Lath available in <strong>chisel point only</strong>.
        </li>
      </Ul>

      <Table>
        <thead>
          <tr>
            <th>Dimensions</th>
            <th>Point Style</th>
            <th>Bundle Size</th>
            <th>Bundles Per Pallet</th>
            <th>Actual Dimensions</th>
          </tr>
        </thead>

        <tbody>
          {lath.map((row, index) => (
            <tr key={index}>
              <td>{row.dimensions}</td>
              <td>{row.style}</td>
              <td>{row.bundle_size}</td>
              <td>{row.bundles_per_pallet}</td>
              <td>{row.actual}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      <em css={{ fontSize: 15 }}>CUSTOM SIZES AVAILABLE UPON REQUEST.</em>
    </Box>
  </Layout>
)

export default Page

const lath = [
  {
    dimensions: '½" × 1 ½" × 48"',
    style: 'Chisel',
    bundle_size: 50,
    bundles_per_pallet: 40,
    actual: '½" × 1 ½"',
  },
  {
    dimensions: '½" × 1 ½" × 48"',
    style: 'None',
    bundle_size: 50,
    bundles_per_pallet: 40,
    actual: '½" × 1 ½"',
  },
]

const stakes = [
  {
    dimensions: '1" × 2" × 12"',
    style: 'Chisel',
    bundle_size: 24,
    bundles_per_pallet: 80,
    actual: '¾" × 1 ⅞"',
  },
  {
    dimensions: '1" × 2" × 18"',
    style: 'Chisel',
    bundle_size: 24,
    bundles_per_pallet: 96,
    actual: '¾" × 1 ⅞"',
  },
  {
    dimensions: '1" × 2" × 24"',
    style: 'Chisel',
    bundle_size: 24,
    bundles_per_pallet: 80,
    actual: '¾" × 1 ⅞"',
  },
  {},
  {
    dimensions: '1" × 3" × 12"',
    style: 'Chisel',
    bundle_size: 24,
    bundles_per_pallet: 96,
    actual: '¾" × 2 ½"',
  },
  {
    dimensions: '1" × 3" × 16"',
    style: 'Chisel',
    bundle_size: 24,
    bundles_per_pallet: 80,
    actual: '¾" × 2 ½"',
  },
  {
    dimensions: '1" × 3" × 18"',
    style: 'Chisel',
    bundle_size: 24,
    bundles_per_pallet: 80,
    actual: '¾" × 2 ½"',
  },
  {
    dimensions: '1" × 3" × 24"',
    style: 'Chisel',
    bundle_size: 24,
    bundles_per_pallet: 72,
    actual: '¾" × 2 ½"',
  },
  {
    dimensions: '1" × 3" × 30"',
    style: 'Chisel',
    bundle_size: 24,
    bundles_per_pallet: 40,
    actual: '¾" × 2 ½"',
  },

  {
    dimensions: '1" × 3" × 36"',
    style: 'Chisel',
    bundle_size: 24,
    bundles_per_pallet: 40,
    actual: '¾" × 2 ½"',
  },
  {},
  {
    dimensions: '1" × 1" × 18"',
    style: 'Pencil',
    bundle_size: 36,
    bundles_per_pallet: 80,
    actual: '⅞" × ⅞"',
  },
  {},
  {
    dimensions: '2" × 2" × 8"',
    style: 'Pencil',
    bundle_size: 24,
    bundles_per_pallet: 50,
    actual: '1 ½" × 1 ½"',
  },
  {
    dimensions: '2" × 2" × 24"',
    style: 'Pencil',
    bundle_size: 24,
    bundles_per_pallet: 72,
    actual: '1 ½" × 1 ½"',
  },
  {
    dimensions: '2" × 2" × 36"',
    style: 'Pencil',
    bundle_size: 24,
    bundles_per_pallet: 40,
    actual: '1 ½" × 1 ½"',
  },
  {
    dimensions: '2" × 2" × 48"',
    style: 'Pencil',
    bundle_size: 20,
    bundles_per_pallet: 39,
    actual: '1 ½" × 1 ½"',
  },
]
